<template>
  <v-app
    id="inspire"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <Header v-model="expandOnHover" :show-icon="true"></Header>
    <v-content>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-content>
    <CompanyFooter></CompanyFooter>
  </v-app>
</template>

<script>
import Header from "./header/Header";
import CompanyFooter from "@/layouts/footer/CompanyFooter";

export default {
  name: "BasicLayout",

  components: {
    CompanyFooter,
    Header,
  },
  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    theme() {
      return "light";
    },
  },
};
</script>
