<template>
  <v-footer dark padless color="primary" class="d-flex justify-center">
    <v-card flat tile class="primary white--text text-center" width="80%">
      <v-card-text class="white--text mt-5 pb-0">
        C.R.I.C Concejo Regional Indígenas del Cauca
      </v-card-text>
      <v-card-text class="white--text pt-2 pb-0">
        <v-icon>mdi-phone</v-icon>
        +57 2 824 0343
      </v-card-text>
      <v-card-text class="white--text pt-0 pb-0">
        <v-icon>mdi-map-marker</v-icon>
        Cll 1 # 4-50 Popayán / Cauca - Colombia
      </v-card-text>
      <v-card-text class="white--text pt-0">
        <v-icon>mdi-email</v-icon>
        comunicaciones@cric-colombia.org
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <strong
              >Todos los derechos reservados {{ new Date().getFullYear() }}
            </strong>
            <div>
              <v-btn v-for="icon in icons" :key="icon.href" class="white--text" icon :href="icon.href">
                <v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "CompanyFooter",
  data: () => ({
    icons: [
      {
        icon : "mdi-facebook",
        href : "https://www.facebook.com/cric.colombia"
      },
      {
        icon : "mdi-twitter",
        href : "https://twitter.com/CRIC_Cauca"
      },
      {
        icon : "mdi-instagram",
        href : "https://www.instagram.com/caucacric"
      },
      {
        icon : "mdi-youtube",
        href : "https://www.youtube.com/user/criccomunicaciones"
      },
      ],
  }),
};
</script>

<style scoped></style>
